<template>
	<main class="page-container" id="reservation-my">
		<reserv-page-header title="내 예약" :my="false" />

		<div class="page-component">
			<div class="box-gray mb24">
				<strong class="is-strong">{{ session.userNm }} 님, 총 {{ totalCount }}건의 예약 내역이 있습니다.</strong>
			</div>
			<div class="reserv-thumbnail-list">
				<div class="thumbnail-article w100" v-for="(item, idx) in reservList" :key="idx">
					<div class="thumbnail-article-body">
						<div class="thumb-default">
							<img :src="item.rprsImgFileUrl" :alt="item.spaceNm + ' - ' + item.roomNm" />
						</div>
						<div class="thumbnail-article-text">
							<div class="thumbnail-article-text__header">
								<span v-if="item.rsvtStsDcd === '1405005'" class="thumbnail-article-tag color-yellow">예약취소</span>
								<span v-else-if="item.rsvtStsDcd === '1405002'" class="thumbnail-article-tag color-green">승인완료</span>
								<span v-else-if="item.rsvtStsDcd === '1405001'" class="thumbnail-article-tag color-blue">신청완료</span>
								<span v-else-if="item.rsvtStsDcd === '1405004'" class="thumbnail-article-tag color-gray">이용완료</span>
								<span v-else-if="item.rsvtStsDcd === '1405006'" class="thumbnail-article-tag color-red">노쇼</span>
								<span v-else-if="item.rsvtStsDcd === '1405003'" class="thumbnail-article-tag color-red">반려</span>
							</div>
							<strong class="thumbnail-article-title">
								{{ item.spaceNm }}
							</strong>
							<strong class="thumbnail-article-title">
								({{ item.roomNm }})
							</strong>
							<span class="thumbnail-article-date" v-for="(time, timeIdx) in item.rsvtTimeDomains" :key="timeIdx">
								{{ timestampToDateFormat(item.rsvtDt) }}
								<br />
								{{ dateToDateFormat(time.rsvtBgngHhmm) }} ~ {{ dateToDateFormat(time.rsvtEndHhmm) }}
							</span>
						</div>
					</div>
					<div class="thumbnail-article-footer buttons" v-if="(item.rsvtStsDcd === '1405002' || item.rsvtStsDcd === '1405001') && isNextDay(item.rsvtDt)">
						<button type="button" class="button-default is-rounded is-outlined" @click.prevent="onClickCancel(item)">예약취소</button>
					</div>
				</div>
			</div>
			<div class="reserv-pagination-wrap">
				<portal-pagination 
					page-name="SpaceMyRsvt" 
					:page-no="pageNo" 
					:page-size="pageSize" 
					:page-list-size="pageListSize"
					:total-count="totalCount" 
					:query="queries" 
					:parent-method-name="pagingMethodName" 
					@pagingChange="pagingChange"
			></portal-pagination>
			</div>
			<loading-modal v-if="isLoading"></loading-modal>
		</div>
	</main>
</template>

<script>
import LoadingModal from '../../../components/common/LoadingModal.vue';
import {mapGetters} from 'vuex';
// import ReservPageHeader from '@/components/reserv/ReservPageHeader';
import ReservPageHeader from '@/components/space/PageHeader';
import PortalPagination from '@/components/PortalPaginationRoute';
import errorImg from '@/assets/img/common/error.png';
import { 
	ACT_GET_SPACE_RSVT_LIST,
}  from '@/store/_act_consts';
import {
	MUT_SHOW_ALERT,
	MUT_SHOW_CONFIRM,
	MUT_SET_RETURN_ROUTE
} from '@/store/_mut_consts';
import {
	getItems,
	setPaging,
	timestampToDateFormat,
	dateToDateFormat,
	isSuccess
} from '@/assets/js/utils';
import { getTokenByKey } from '@/assets/js/jwt.service';
import { ACT_UPDATE_SPACE_RSVT_STATUS } from '../../../store/_act_consts';
export default {
	name: 'SpaceMyRsvt',
	components: { 
		ReservPageHeader,
		PortalPagination, 
		LoadingModal },
	data: () => ({
		totalCount: 10,
		pageSize: 10,
		pageNo: 1,
		pageListSize: 10,
		pagingMethodName: 'pagingChange',
		queries: {},
		reservList: [],
		isLoading: false
	}),
	watch: {},
	computed: {
		...mapGetters('common', ['isMobile']),
    	...mapGetters('auth', ['isAuth', 'session']),
	},
	created() {
		this.isLoginCheck();
		this.getRsvtList();
	},
	mounted() {
		this.$nextTick(() => {});
	},
	methods: {
		isLoginCheck() {
			if (!getTokenByKey('auth_token')) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}
		},
		async getRsvtList() {
			const payload = {
				pageNo: this.pageNo,
				pageSize: this.pageSize,
			};
			// api 호출
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_RSVT_LIST}`, payload)
				.then((res) => {
					if (this.reservList.length > 0) this.reservList.length = 0;
					this.reservList = this.reservList.concat(getItems(res));
					setPaging(this, res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		async cancelRsvt(item) {
			const payload = {
				rsvtId: item.rsvtId
				, spaceId: item.spaceId
				, roomId: item.roomId
				, rsvtStsDcd: '1405005'
			}
			this.$store.dispatch(`space/${ACT_UPDATE_SPACE_RSVT_STATUS}`, payload)
			.then(res => {
				if(isSuccess(res)){
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `예약이 취소 되었습니다.`,
						yesfunc: () => {
							this.isLoading = false
							this.getRsvtList()
						},
						rightNow: true
					});
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
				}							
			});
		},
		pagingChange(no) {
			this.pageNo = no;
      		this.getRsvtList();
		},
		getTimestamp(dateString) {
			return new Date(dateString).getTime();
		},
		isNextDay(regDt) {
			const regTimestamp = this.getTimestamp(regDt);
			const today = new Date();
			const todayTimestamp = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
			return regTimestamp > todayTimestamp;
		},
		timestampToDateFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy-MM-dd(w)');
		},
		dateToDateFormat(hhmm) {
			if(!hhmm || hhmm.length !== 4) return hhmm
			return `${hhmm.substring(0, 2)}:${hhmm.substring(2, 4)}`
		},
		handleImgError(e) {
			e.target.src = errorImg;
		},
		onClickCancel(item) {
			if(this.isLoading) return;
			this.isLoading = true;
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: '예약을 취소 하시겠습니까?',
				showCancelButton: true,
				yesfunc: ()=>{
					this.cancelRsvt(item)
				},
				nofunc: () => {this.isLoading = false;},
			});
		},
	},
};
</script>